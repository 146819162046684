import React from "react"

import LayoutOrig from "../../../components/layout_fr"
import Seo from "../../../components/seo"
import { Link, graphql } from "gatsby"
import {Container, Row, Col, Card, Button} from "react-bootstrap"
import { IoChevronForwardOutline } from "@react-icons/all-files/io5/IoChevronForwardOutline";
/*import CONFIG from '../../global-vars-trezo.js'*/
import {GatsbyImage} from "gatsby-plugin-image";
import trezoPlan from "../../../images/tree.svg"



const Suivi = ({data}) => (
    <LayoutOrig>

        <Seo
            titleTemplate={`Logiciel de gestion de trésorerie automatisée en temps réel | Trezorino`}
            title="Logiciel de gestion de trésorerie automatisée en temps réel"
            description="La gestion de trésorerie automatisée pour gagner du temps, réduire le risque d’erreurs et éliminer les tâches peu productives"
            image={trezoPlan}
            lang="fr"
        />

        <div>

            <Container>
                {data.first.edges.map(document => (
                    <Row className="home-box-header justify-content-center" key={document.node.id} >
                        <Col className="my-auto home-box-text order-2 order-sm-1 order-lg-1">

                            <h1 className="title">{document.node.Title}</h1>
                            <p className="section">Oubliez votre fichier excel et bénéficiez d’un tableau de trésorerie digital, alimenté automatiquement et en temps réel. </p>
                            <p className="section">Trezorino vous offre une vue complète de votre situation financière passée, présente et future.</p>
                            <p className="section">L’analyse de votre trésorerie devient plus facile et vous permet d'identifier rapidement où focaliser votre attention.</p>


                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button variant="demo"  style={{width:"100%"}} href="/fr/demande-demo/">Demander une demo</Button>
                                </Col>
                                {/*<Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button  variant="try" style={{width:"100%"}} href={`${process.env.APP_URL}/signup`}>Essayer gratuitement</Button>
                                </Col>*/}
                            </Row>

                        </Col>

                        <Col className="home-box-image order-1 order-sm2 order-lg-2 d-flex align-items-center justify-content-center" xs={12} sm={12} lg={7} xl={7} md={7}>

                            {/*  {section1.node.video &&

                            <video className="box-shadow" key={section1.node.video.id} src={section1.node.video.url} width="100%" height="100%" loop muted playsInline autoPlay></video>
                        }*/}

                            {document.node.MainImage?.map( image=> (
                                <GatsbyImage fluid={image.localFile.childImageSharp.fluid} className="" key={image?.id} image={image?.localFile.childImageSharp.gatsbyImageData} alt=""/>
                            ))}

                        </Col>

                    </Row>
                ))}
            </Container>

            <Container className="container-blue-inverse container-line" >
                {data.second.edges.map(document => (
                    <Row className="justify-content-center" key={document.node.id}>
                        <Col className="home-box-image order-1 order-sm1 order-lg-1 d-flex align-items-center justify-content-center" xs={12} sm={12} lg={7} xl={7} md={7}>

                            {document.node.MainImage?.map( image=> (
                                <GatsbyImage fluid={image.localFile.childImageSharp.fluid} key={image?.id} image={image?.localFile.childImageSharp.gatsbyImageData} alt=""/>
                            ))}

                        </Col>
                        <Col className="my-auto home-box-text order-2 order-sm-2 order-lg-2">

                            <h2 className="title">Synchronisation de vos comptes bancaires</h2>
                            <p className="section-h2">Avec plus de 300 connecteurs bancaires en France et en Europe, votre trésorerie est automatiquement mise à jour. </p>
                            <p className="section-h2">Évitez ainsi les erreurs et les manipulations fastidieuses avec les imports manuels de vos transactions bancaires dans Excel.</p>


                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button variant="demo"  style={{width:"100%"}} href="/fr/demande-demo/">Demander une demo</Button>
                                </Col>
                                {/*<Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button  variant="try" style={{width:"100%"}} href={`${process.env.APP_URL}/signup`}>Essayer gratuitement</Button>
                                </Col>*/}
                            </Row>

                        </Col>

                    </Row>
                ))}
            </Container>

            <Container className="container-line" >
                {data.trois.edges.map(document => (
                    <Row className="justify-content-center" key={document.node.id}>
                        <Col className="my-auto home-box-text order-1 order-sm-1 order-lg-1">

                            <h2 className="title">Catégorisation automatique de vos flux de trésorerie</h2>
                            <p className="section-h2">Votre tableau de trésorerie est structuré par catégorie de recettes et de dépenses. Déterminez les catégories qui conviennent à votre activité pour une meilleure analyse de vos entrées et sorties d’argent.</p>
                            <p className="section-h2">Nos algorithmes intelligents catégorisent automatiquement les transactions de sorte que votre tableau de trésorerie est mis à jour automatiquement.</p>


                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button variant="demo"  style={{width:"100%"}} href="/fr/demande-demo/">Demander une demo</Button>
                                </Col>
                                {/*<Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button  variant="try" style={{width:"100%"}} href={`${process.env.APP_URL}/signup`}>Essayer gratuitement</Button>
                                </Col>*/}
                            </Row>

                        </Col>
                        <Col className="home-box-image order-2 order-sm2 order-lg-2 d-flex align-items-center justify-content-center" xs={12} sm={12} lg={7} xl={7} md={7}>

                            {document.node.MainImage?.map( image=> (
                                <GatsbyImage fluid={image.localFile.childImageSharp.fluid} key={image?.id} image={image?.localFile.childImageSharp.gatsbyImageData} alt=""/>
                            ))}

                        </Col>

                    </Row>
                ))}
            </Container>

            <Container className="container-blue container-line" >
                {data.quatre.edges.map(document => (
                    <Row className="justify-content-center" key={document.node.id}>
                        <Col className="home-box-image order-1 order-sm1 order-lg-1 d-flex align-items-center justify-content-center" xs={12} sm={12} lg={7} xl={7} md={7}>

                            {document.node.MainImage?.map( image=> (
                                <GatsbyImage fluid={image.localFile.childImageSharp.fluid} key={image?.id} image={image?.localFile.childImageSharp.gatsbyImageData} alt=""/>
                            ))}

                        </Col>
                        <Col className="my-auto home-box-text order-2 order-sm-2 order-lg-2">

                            <h2 className="title">Rapprochement bancaire intelligent</h2>
                            <p className="section-h2">Ne perdez pas votre temps précieux à effectuer manuellement les rapprochements bancaires.</p>
                            <p className="section-h2">Trezorino accélère ce processus et génère automatiquement des suggestions de rapprochement pour mieux vous aider dans cette tâche.</p>


                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button variant="demo"  style={{width:"100%"}} href="/fr/demande-demo/">Demander une demo</Button>
                                </Col>
                                {/*<Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button  variant="try" style={{width:"100%"}} href={`${process.env.APP_URL}/signup`}>Essayer gratuitement</Button>
                                </Col>*/}
                            </Row>

                        </Col>

                    </Row>
                ))}
            </Container>

            <Container className="container-line" >
                {data.cinq.edges.map(document => (
                    <Row className="justify-content-center" key={document.node.id}>
                        <Col className="my-auto home-box-text order-1 order-sm-1 order-lg-1">

                            <h2 className="title">Un tableau de bord de trésorerie pour chaque entité</h2>
                            <p className="section-h2">Créez autant de tableaux de trésorerie qu'il y a d'entités ou d'agences.</p>
                            <p className="section-h2">Gérez les flux de trésorerie entre vos entités et restez maître de la stratégie financière de votre groupe.</p>


                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button variant="demo"  style={{width:"100%"}} href="/fr/demande-demo/">Demander une demo</Button>
                                </Col>
                                {/*<Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button  variant="try" style={{width:"100%"}} href={`${process.env.APP_URL}/signup`}>Essayer gratuitement</Button>
                                </Col>*/}
                            </Row>

                        </Col>
                        <Col className="home-box-image order-2 order-sm2 order-lg-2 d-flex align-items-center justify-content-center" xs={12} sm={12} lg={7} xl={7} md={7}>

                            {document.node.MainImage?.map( image=> (
                                <GatsbyImage fluid={image.localFile.childImageSharp.fluid} key={image?.id} image={image?.localFile.childImageSharp.gatsbyImageData} alt=""/>
                            ))}

                        </Col>

                    </Row>
                ))}
            </Container>

            <Container>
                {data.allStrapiTitleMenuFooter.edges.map(title => (
                    <Row key={title.node.id}  className="justify-content-center pb-4 pt-5">
                        <h2 className="center message-title">{title.node.Title}</h2>
                    </Row>
                ))}



                <Row xs={2} sm={2} md={2} lg={4} xl={4} className="g-4" style={{backgroundColor: "transparent"}}>

                    {data.allStrapiFooterMenus.edges.map(menu => (

                        <Card className="card-box" key={menu.node.id}>
                            {menu.node.MainImage.map( ImageCard=> (
                                <GatsbyImage key={ImageCard.id}  image={ImageCard?.localFile.childImageSharp.gatsbyImageData} alt="text a définir"/>
                            ))}
                            <Card.Body>
                                <Card.Title className="center card-title" >{menu.node.Title}</Card.Title>
                            </Card.Body>
                            <Card.Footer className="justify-content-center card-footer">
                                <Link to={"/fr"+menu.node.LinkHref+"/"}  className="card-link-footer">En savoir plus<IoChevronForwardOutline></IoChevronForwardOutline></Link>
                                {/* <Button variant="primary" className="collab-button-footer-card"  href="/fr"+menu.node.LinkHref >Essai gratuit</Button> */}
                            </Card.Footer>
                        </Card>

                    ))}

                </Row>








                <Row className="justify-content-md-center pb-5 pt-5">

                </Row>

            </Container>



        </div>


    </LayoutOrig>
)

export default Suivi



export const data = graphql`  
query suivi_fr {
  first : allStrapiFeaturesSuivis(filter: {id: {eq: "Features-suivis_1"}}) {
    edges {
      node {
        id
        ShowTryLink
        Title
        Description
        Order
        AltImage
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image480 {
          id
          url
        }
        Image768 {
          id
          url
        }
        Image992 {
          id
          url
        }
      }
    }
  }
  second : allStrapiFeaturesSuivis(filter: {id: {eq: "Features-suivis_2"}}) {
    edges {
      node {
        id
        ShowTryLink
        Title
        Description
        Order
        AltImage
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image480 {
          id
          url
        }
        Image768 {
          id
          url
        }
        Image992 {
          id
          url
        }
      }
    }
  }
  trois : allStrapiFeaturesSuivis(filter: {id: {eq: "Features-suivis_3"}}) {
    edges {
      node {
        id
        ShowTryLink
        Title
        Description
        Order
        AltImage
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image480 {
          id
          url
        }
        Image768 {
          id
          url
        }
        Image992 {
          id
          url
        }
      }
    }
  }
  quatre : allStrapiFeaturesSuivis(filter: {id: {eq: "Features-suivis_4"}}) {
    edges {
      node {
        id
        ShowTryLink
        Title
        Description
        Order
        AltImage
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image480 {
          id
          url
        }
        Image768 {
          id
          url
        }
        Image992 {
          id
          url
        }
      }
    }
  }
  cinq : allStrapiFeaturesSuivis(filter: {id: {eq: "Features-suivis_5"}}) {
    edges {
      node {
        id
        ShowTryLink
        Title
        Description
        Order
        AltImage
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image480 {
          id
          url
        }
        Image768 {
          id
          url
        }
        Image992 {
          id
          url
        }
      }
    }
  }
  all : allStrapiFeaturesSuivis(
    filter: {id: {ne: "Features-suivis_1"}}
    sort: {order: ASC, fields: Order}
  ) {
    edges {
      node {
        id
        ShowTryLink
        Title
        Description
        Order
        AltImage
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image480 {
          id
          url
        }
        Image768 {
          id
          url
        }
        Image992 {
          id
          url
        }
      }
    }
  }
  allStrapiFooterMenus (
  sort: {order: ASC, fields: Order}
  ){
    edges {
      node {
        id
        Title
        LinkText
        LinkHref
        Order
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
      }
    }
  }
  allStrapiTitleMenuFooter {
    edges {
      node {
        id
        Title
      }
    }
  }
}
`
